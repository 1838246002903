import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Hero from '../components/Hero'
import ArchiveNewsList from '../components/ArchiveNewsList'

export const query = graphql`
  query ArchiveQuery($lang: String) {
    prismic {
      data: allNews_archives(lang: $lang) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            meta: _meta {
              lastPublicationDate
              firstPublicationDate
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroHeading: hero_heading
            heroBody: hero_body
            hero_image1
            heroImage1Sharp: hero_image1Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            hero_image2
            heroImage2Sharp: hero_image2Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

const PortfolioContainer = ({ pageContext, ...props }) => {
  const data = get(props, 'data.prismic.data.edges[0].node')
  const articles = pageContext.initialArticles

  return (
    <>
      <Hero {...data} />
      <ArchiveNewsList articles={articles} {...pageContext} />
    </>
  )
}

// PortfolioContainer.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default PortfolioContainer
