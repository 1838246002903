import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'

// component
// ==========================================================================================
const FilterButton = ({ filter, onClick, className, isActive, children }) => {
  return (
    <button
      onClick={onClick}
      className={classNames(css.button, className, { [css.active]: isActive })}
      type="button"
      data-filter={filter}
    >
      {children}
    </button>
  )
}

export default FilterButton
