import React, { useState, useEffect, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import css from './styles.module.scss'
import NewsList from '../NewsList'
import Filters from './Filters'
import useTranslation from '../../hooks/useTranslation'

const enterDuration = 0.7
const exitDuration = 0.25

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: { duration: enterDuration },
  },
  exit: {
    opacity: 0,
    transition: { duration: exitDuration },
  },
}

// component
// ==========================================================================================
const ArchiveNewsList = ({ articles }) => {
  if (typeof URLSearchParams === 'undefined') {
    return null
  }
  const itemsPerPage = 10
  const isClient = typeof window !== 'undefined'
  const searchParams = new URLSearchParams(isClient && window.location.search)
  const initialFilter = searchParams.get('filter')
  const initialFilterIsNull = initialFilter === 'null'
  const [filter, setFilter] = useState(
    initialFilterIsNull ? null : initialFilter
  )
  const [filteredArticles, setFilteredArticles] = useState(articles || [])
  const { t } = useTranslation()
  const initialPage = Number(searchParams.get('page'))
  const [page, setPage] = useState(initialPage || 1)
  const allLoaded = page * itemsPerPage >= filteredArticles.length

  function setQueryParams(param, value) {
    const { pathname } = window.location
    searchParams.set(param, value)
    window.history.pushState(null, '', `${pathname}?${searchParams.toString()}`)
  }

  useEffect(() => {
    if (filter && filter !== 'null') {
      const filtered = articles.filter(({ node }) => node.type === filter)
      setFilteredArticles(filtered)
    } else {
      setFilteredArticles(articles)
    }
    setQueryParams('filter', filter)
  }, [filter])

  const handleLoadMore = useCallback(() => {
    const newPage = page + 1
    setQueryParams('page', newPage)
    setPage(newPage)
  }, [page])

  const handleFilter = useCallback((newFilter) => {
    setFilter(newFilter)
    setQueryParams('page', 1)
    setPage(1)
  }, [])

  return (
    <>
      <Filters {...{ filter, handleFilter }} />
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={filter || 'all'}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          className={css.animator}
        >
          <NewsList articles={filteredArticles.slice(0, page * itemsPerPage)} />
        </motion.div>
      </AnimatePresence>
      <button
        className={css.more}
        onClick={handleLoadMore}
        type="button"
        disabled={allLoaded}
      >
        {t(allLoaded ? 'noMorePagination' : 'showMorePagination')}
      </button>
    </>
  )
}

export default ArchiveNewsList
