import React, { useCallback } from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import useTranslation from '../../hooks/useTranslation'
import { archiveFilters, newsTypes } from '../../utils/filters'
import FilterButton from '../FilterButton'

// component
// ==========================================================================================
const Filters = ({ filter, handleFilter }) => {
  const { t } = useTranslation()

  const setFilter = useCallback((event) => {
    handleFilter(event.target.dataset.filter)
  }, [])

  const clearFilter = useCallback(() => {
    handleFilter(null)
  }, [])

  return (
    <div className={css.filtersWrap}>
      <div className="row">
        <div className="col-sm-12 col-md-10 offset-md-1">
          <button
            onClick={clearFilter}
            className={classNames(css.button, { [css.active]: !filter })}
            data-filter="all"
            type="button"
          >
            {t('filterAll')}
          </button>
          {archiveFilters.map((key, i) => (
            <FilterButton
              onClick={setFilter}
              isActive={filter === newsTypes[i]}
              filter={newsTypes[i]}
              key={key}
            >
              {key}
            </FilterButton>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Filters
